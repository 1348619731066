import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './Badge.module.css';

const Badge = props => {
  const { className, variant = 'default', children } = props;
  const classes = classNames(
    css.root,
    {
      [css.default]: variant === 'default',
      [css.secondary]: variant === 'secondary',
      [css.destructive]: variant === 'destructive',
      [css.outline]: variant === 'outline',
    },
    className
  );

  return <div className={classes}>{children}</div>;
};

Badge.defaultProps = {
  className: null,
  variant: 'default',
};

Badge.propTypes = {
  className: PropTypes.string,
  variant: PropTypes.oneOf(['default', 'secondary', 'destructive', 'outline']),
  children: PropTypes.node.isRequired,
};

export default Badge; 